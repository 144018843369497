<template>
  <header
    v-if="headerVisible"
    class="fixed left-0 right-0 h-20 border-b border-surface-60 bg-surface-10 grid items-center content-center px-5 lg:px-0 lg:grid-cols-[224px,1fr] z-10"
  >
    <div class="grid h-20">
      <h1 class="flex justify-center self-center">
        <Img
          :src="`${cdn}/heltia-logo.webp`"
          alt="Heltia Logo"
          width="128"
          @click="$router.push('/')"
          class="cursor-pointer"
        />
      </h1>
    </div>
    <WebExpHeader />
  </header>
  <main
    id="app"
    class="min-h-screen w-screen bg-surface-base"
    :class="{
      'pt-20': headerVisible,
    }"
  >
    <SalusLoading
      v-if="!isAuthStoreInitialized"
      :isLoading="!isAuthStoreInitialized"
    />
    <slot v-else />
  </main>
</template>
<script setup lang="ts">
import { useAuthStore } from "~/store/auth";
import { useMeetingStore } from "~/store/meeting";

const config = useRuntimeConfig();
const authStore = useAuthStore();
const meetingStore = useMeetingStore();

const isAuthStoreInitialized = computed(() => authStore.getIsInitialized);
const headerVisible = computed(() => !meetingStore.isViewstateInCall);
const cdn = config.public.cdn;
</script>
